import { default as indexBtuh13VFGgMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/index.vue?macro=true";
import { default as loginH97FeqlSqZMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/login.vue?macro=true";
import { default as indexBNnrRzaFfNMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/orders/[id]/index.vue?macro=true";
import { default as returndP5gyqsVxDMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/orders/[id]/return.vue?macro=true";
import { default as createKWvS3vhlyAMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/orders/create.vue?macro=true";
import { default as indexJBI6zmAAXXMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/orders/index.vue?macro=true";
import { default as print_45jobskWGnHGegDiMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/print-jobs.vue?macro=true";
import { default as indexlBnfAGcOIUMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/reservations/index.vue?macro=true";
import { default as lanesBWGBGYZhb3Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/reservations/lanes.vue?macro=true";
import { default as settlementdhDymv4reUMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/settlement.vue?macro=true";
import { default as indexNJL5Yv4c8pMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/setup/[merchantId]/index.vue?macro=true";
import { default as _91storeId_93y8phAcndG6Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/setup/[merchantId]/stores/[storeId].vue?macro=true";
import { default as indexUx5oGms4hyMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/setup/index.vue?macro=true";
import { default as stock_45statusesAHaK7xWGo4Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/stock-statuses.vue?macro=true";
import { default as indexUoe6PDREL4Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/stock/index.vue?macro=true";
import { default as create04apsof1IvMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/stock/statuses/create.vue?macro=true";
import { default as create82WkvC1kVWMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/stock/transfers/create.vue?macro=true";
import { default as indexAwmj2oPBiOMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/tables/[tableId]/orders/[orderId]/index.vue?macro=true";
import { default as splitZs7Q1EBZk2Meta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/tables/[tableId]/orders/[orderId]/split.vue?macro=true";
import { default as _91orderId_93Jorn8PYe3CMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/tables/[tableId]/orders/[orderId].vue?macro=true";
import { default as createhCp2b1O49WMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/tables/[tableId]/orders/create.vue?macro=true";
import { default as _91tableId_936Gou1Qd4iGMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/tables/[tableId].vue?macro=true";
import { default as indexPHfzVgsjbQMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/tables/index.vue?macro=true";
import { default as indexdrFpoDOL0yMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/takeaway-orders/[id]/index.vue?macro=true";
import { default as splitGBsWTaZ3TfMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/takeaway-orders/[id]/split.vue?macro=true";
import { default as _91id_93MZDjUTYyPfMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/takeaway-orders/[id].vue?macro=true";
import { default as createPmBDubIkyoMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/takeaway-orders/create.vue?macro=true";
import { default as index1oDHzQtmyIMeta } from "/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/takeaway-orders/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginH97FeqlSqZMeta || {},
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-return",
    path: "/orders/:id()/return",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/orders/[id]/return.vue").then(m => m.default || m)
  },
  {
    name: "orders-create",
    path: "/orders/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "print-jobs",
    path: "/print-jobs",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/print-jobs.vue").then(m => m.default || m)
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: "reservations-lanes",
    path: "/reservations/lanes",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/reservations/lanes.vue").then(m => m.default || m)
  },
  {
    name: "settlement",
    path: "/settlement",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/settlement.vue").then(m => m.default || m)
  },
  {
    name: "setup-merchantId",
    path: "/setup/:merchantId()",
    meta: indexNJL5Yv4c8pMeta || {},
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/setup/[merchantId]/index.vue").then(m => m.default || m)
  },
  {
    name: "setup-merchantId-stores-storeId",
    path: "/setup/:merchantId()/stores/:storeId()",
    meta: _91storeId_93y8phAcndG6Meta || {},
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/setup/[merchantId]/stores/[storeId].vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    meta: indexUx5oGms4hyMeta || {},
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/setup/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses",
    path: "/stock-statuses",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/stock-statuses.vue").then(m => m.default || m)
  },
  {
    name: "stock",
    path: "/stock",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/stock/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "/stock/statuses/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/stock/statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-transfers-create",
    path: "/stock/transfers/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/stock/transfers/create.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId",
    path: "/tables/:tableId()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/tables/[tableId].vue").then(m => m.default || m),
    children: [
  {
    name: _91orderId_93Jorn8PYe3CMeta?.name,
    path: "orders/:orderId()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/tables/[tableId]/orders/[orderId].vue").then(m => m.default || m),
    children: [
  {
    name: "tables-tableId-orders-orderId",
    path: "",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/tables/[tableId]/orders/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId-orders-orderId-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/tables/[tableId]/orders/[orderId]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables-tableId-orders-create",
    path: "orders/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/tables/[tableId]/orders/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables",
    path: "/tables",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/tables/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93MZDjUTYyPfMeta?.name,
    path: "/takeaway-orders/:id()",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/takeaway-orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "takeaway-orders-id",
    path: "",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/takeaway-orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders-id-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/takeaway-orders/[id]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "takeaway-orders-create",
    path: "/takeaway-orders/create",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/takeaway-orders/create.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders",
    path: "/takeaway-orders",
    component: () => import("/var/www/sites/terminal.staging.ultrafedt.dk/releases/20250403123442/pages/takeaway-orders/index.vue").then(m => m.default || m)
  }
]