<template>
  <component
    :is="component"
    :to="to"
    :class="[
      'text-base inline-block whitespace-nowrap font-medium uppercase relative rounded-md',
      paddingClass,
      textColorClass,
      disabled && active ? 'bg-stone-500 cursor-not-allowed' : '',
      disabled && !active ? 'bg-stone-400 cursor-not-allowed' : '',
      !disabled ? backgroundColorClass : '',
      !disabled && active ? 'brightness-75' : '',
    ]"
    :style="{ backgroundColor: disabled ? '' : color }"
    :disabled="disabled"
  >
    <div :class="['flex justify-center items-center', contentClass]">
      <component :is="iconBefore" v-if="iconBefore" class="mr-2 h-5 w-5" />

      <slot />

      <component :is="iconAfter" v-if="iconAfter" class="ml-2 h-5 w-5" />
    </div>

    <div
      v-if="forceSecondaryText || secondaryText"
      class="font-light text-sm text-center"
    >
      {{ secondaryText || "&nbsp;" }}
    </div>

    <div
      v-if="count"
      class="absolute top-1 right-1 bg-teal-950 rounded-md h-5 px-1.5 flex items-center justify-center text-xs"
    >
      {{ count }}
    </div>
  </component>
</template>

<script setup>
const props = defineProps({
  to: {
    required: false,
    type: String,
    default: null,
  },

  disabled: {
    required: false,
    type: Boolean,
    default: false,
  },

  active: {
    required: false,
    type: Boolean,
    default: false,
  },

  color: {
    required: false,
    type: String,
    default: null,
  },

  count: {
    required: false,
    type: Number,
    default: 0,
  },

  forceSecondaryText: {
    required: false,
    type: Boolean,
    default: false,
  },

  secondaryText: {
    required: false,
    type: String,
    default: null,
  },

  iconBefore: {
    required: false,
    type: [Function, Object],
    default: null,
  },

  iconAfter: {
    required: false,
    type: [Function, Object],
    default: null,
  },

  textColorClass: {
    required: false,
    type: String,
    default: "text-white",
  },

  paddingClass: {
    required: false,
    type: String,
    default: "py-2.5 px-3",
  },

  backgroundColorClass: {
    required: false,
    type: String,
    default: null,
  },

  contentClass: {
    required: false,
    type: String,
    default: null,
  },
});

const component = computed(() => {
  if (props.to) {
    if (props.disabled) {
      return "div";
    }

    return resolveComponent("NuxtLink");
  }

  return "button";
});
</script>
