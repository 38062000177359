import { navigateTo } from "#app";

export const useMerchantApi = async (merchantId, path, options = {}) => {
  const runtimeConfig = useRuntimeConfig();
  const token = useCookie("jwt_token");

  useBugsnag().leaveBreadcrumb(path, options, "request");

  try {
    return await $fetch(
      runtimeConfig.public.apiUrl + "/api/v1/merchant" + path,
      {
        ...options,
        headers: {
          Accept: "application/json",
          Merchant: merchantId,
          Authorization: "Bearer " + token.value,
          ...options.headers,
        },
      },
    );
  } catch (error) {
    useBugsnag().leaveBreadcrumb(error.data.error, error.data.data, "request");

    if (error.status === 401) {
      return navigateTo(runtimeConfig.public.authDomain, { external: true });
    }

    throw error;
  }
};
